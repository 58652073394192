import App from "./App.vue";
import { createApp } from "vue";
import { createPinia } from "pinia";
import { createWebHashHistory, createRouter } from "vue-router";
import "./style.css";
import "./custom.css";

import './assets/js/vendors/jquery-3.5.1.min.js';
import './assets/js/vendors/bootstrap.bundle.min.js';
import './assets/js/vendors/bootstrap-slider.min.js';
import './assets/js/vendors/jquery.countdown.min.js';
import './assets/js/vendors/jquery.easing.min.js';
import './assets/js/vendors/owl.carousel.min.js';
import './assets/js/vendors/validator.min.js';
import './assets/js/vendors/jquery.waypoints.min.js';
import './assets/js/vendors/jquery.rcounterup.js';
import './assets/js/vendors/magnific-popup.min.js';
import './assets/js/vendors/hs.megamenu.js';
import './assets/js/app.js';

const pinia = createPinia();

const routes = [
  {
    path: "/",
    component: () => import("./Home.vue"),
    meta: { transition: "fade", title: "Home" },
  },
  {
    path: "/arquiva.email",
    component: () => import("./Arquiva.vue"),
    meta: { transition: "scale", title: "Arquivamento de e-mails" },
  },
  {
    path: "/sobre-nos",
    component: () => import("./About.vue"),
    meta: { transition: "scale", title: "Sobre nós" },
  },
  {
    path: "/consultoria-cloud",
    component: () => import("./Consulting.vue"),
    meta: { transition: "scale", title: "Consultoria Cloud" },
  },
  {
    path: "/suporte",
    component: () => import("./Support.vue"),
    meta: { transition: "scale", title: "Suporte" },
  },
  {
    path: "/hospedagem",
    component: () => import("./Hosting.vue"),
    meta: { transition: "scale", title: "Hospedagem Empresarial" },
  },
  {
    path: "/backup",
    component: () => import("./Backup.vue"),
    meta: { transition: "scale", title: "Backup de Arquivos" },
  },
  {
    path: "/:pathMatch(.*)*",
    component: () => import("./404.vue"),
    meta: { transition: "scale", title: "Página não encontrada" },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    console.log(to);
    console.log(from);
    return new Promise((resolve) => {
      setTimeout(() => {
        if (savedPosition) {
          window.scrollTo({
            top: savedPosition.top,
            behavior: "smooth",
          });
        } else {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }
        resolve();
      }, 100);
    });
  },
});

router.beforeEach((to, _from, next) => {
  const title = to.meta.title as string;
  if (title) {
    document.title = title + " - Cloudger";
  }

  // Forçando que a descrição seja uma string, mesmo que esteja faltando
  const description = (to.meta.description as string) || "Default description";
  let metaDescription = document.querySelector('meta[name="description"]');

  if (!metaDescription) {
    // Cria a meta tag se ela não existir
    metaDescription = document.createElement("meta");
    metaDescription.setAttribute("name", "description");
    document.head.appendChild(metaDescription);
  }

  metaDescription.setAttribute("content", description);

  next();
});

const app = createApp(App);
app.use(pinia);
app.use(router);
app.mount("#app");
