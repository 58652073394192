<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount } from 'vue';

const navBar = ref<HTMLElement | null>(null);

function closeMenu() {
  if (navBar.value && navBar.value.classList.contains('show')) {
    navBar.value.classList.remove('show');
  }
}

function handleClickOutside(event: MouseEvent) {
  if (navBar.value && !navBar.value.contains(event.target as Node)) {
    closeMenu();
  }
}

onMounted(() => {
  document.addEventListener('click', handleClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside);
});
</script>



<template>

  <header id="header" class="header-main">
    <!--topbar start-->
    <div id="header-top-bar" class="gray-light-bg">
      <div class="container">
        <div class="row justify-content-between">
          <div class="col-md-7 col-lg-7">
            <div class="topbar-text d-none d-md-block d-lg-block">
              <ul class="list-inline">
                <li class="list-inline-item">
                  <a href="https://wa.me/12076005502?text=Olá" target="_blank"><span
                      class="fa-brands fa-whatsapp mr-2"></span> Suporte Técnico</a>
                </li>
                <li class="list-inline-item">
                  <a href="https://wiki.cloudger.host" target="_blank"><span
                      class="fa-brands fa-wikipedia-w mr-2"></span> Wiki</a>
                </li>
                <li class="list-inline-item">
                  <a href="https://cloudger.statuspage.io/" target="_blank"><span
                      class="fa-brands fa-whatsapp mr-2"></span> Status</a>
                </li>
                <!-- <li class="list-inline-item"><a href="#"><span class="fas fa-comments mr-2"></span> Live
                    Chat</a></li> -->
              </ul>
            </div>
          </div>
          <div class="col-md-4 col-lg-4">
            <div class="topbar-text">
              <ul class="list-inline text-right">
                <!-- <li class="list-inline-item"><a href="#"><span class="fas fa-user mr-2"></span> Login</a></li>
                <li class="list-inline-item"><a href="#"><span class="fas fa-lock mr-2"></span> Register</a> -->
                <!-- </li> -->
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--topbar end-->

    <!--main header menu start-->
    <div id="logoAndNav" class="main-header-menu-wrap white-bg border-bottom">
      <div class="container">
        <nav class="js-mega-menu navbar navbar-expand-md header-nav">
          <!--logo start-->
          <RouterLink to="/" class="navbar-brand" href="/">
            <img src="assets/img/logo-color.png" width="200" alt="logo" class="img-fluid" />
          </RouterLink>
          <!--logo end-->

          <!--responsive toggle button start-->
          <button type="button" class="navbar-toggler btn" aria-expanded="false" aria-controls="navBar"
            data-toggle="collapse" data-target="#navBar">
            <span id="hamburgerTrigger">
              <span class="fas fa-bars"></span>
            </span>
          </button>
          <!--responsive toggle button end-->

          <!--main menu start-->
          <div id="navBar" class="collapse navbar-collapse" ref="navBar">
            <ul class="navbar-nav ml-auto main-navbar-nav">
              <!--home start-->
              <li class="nav-item custom-nav-item">
                <RouterLink to="/" class="nav-link custom-nav-link" activeClass="active" @click="closeMenu">Home
                </RouterLink>
              </li>
              <!--home end-->

              <!--about start-->
              <li class="nav-item custom-nav-item">
                <RouterLink to="/sobre-nos" class="nav-link custom-nav-link" activeClass="active" @click="closeMenu">
                  Sobre nós</RouterLink>
              </li>
              <!--about end-->

              <!--hosting start-->
              <li class="nav-item hs-has-mega-menu custom-nav-item" data-max-width="720px" data-position="right">
                <a id="hostingMegaMenu" class="nav-link custom-nav-link main-link-toggle" href="JavaScript:Void(0);"
                  aria-haspopup="true" aria-expanded="false">Serviços</a>
                <!-- Demos - Mega Menu -->
                <div class="hs-mega-menu w-100 main-sub-menu" aria-labelledby="hostingMegaMenu">
                  <div class="row no-gutters">
                    <div class="col-md-6">
                      <!--menu title with subtitle and icon item start-->
                      <div class="title-with-icon-item">
                        <RouterLink class="title-with-icon-link" to="/hospedagem" @click="closeMenu">
                          <div class="media">
                            <div class="menu-item-icon"><i class="fas fa-server"></i></div>
                            <div class="media-body">
                              <span class="u-header__promo-title">Hospedagem Cloud Empresarial</span>
                              <small class="u-header__promo-text">Seu site e e-mails seguros</small>
                            </div>
                          </div>
                        </RouterLink>
                      </div>
                      <!--menu title with subtitle and icon item end-->
                      <!--menu title with subtitle and icon item start-->
                      <div class="title-with-icon-item">
                        <RouterLink class="title-with-icon-link" to="/arquiva.email" @click="closeMenu">
                          <div class="media">
                            <div class="menu-item-icon"><i class="fas fa-box"></i></div>
                            <div class="media-body">
                              <span class="u-header__promo-title">Arquiva.email <span
                                  class="badge badge-success ml-1">Exclusivo</span></span>
                              <small class="u-header__promo-text">Arquivamento de e-mails antigos</small>
                            </div>
                          </div>
                        </RouterLink>
                      </div>
                      <!--menu title with subtitle and icon item end-->
                    </div>

                    <div class="col-md-6">
                      <!--menu title with subtitle and icon item start-->
                      <div class="title-with-icon-item">
                        <RouterLink class="title-with-icon-link" to="/consultoria-cloud" @click="closeMenu">
                          <div class="media">
                            <div class="menu-item-icon"><i class="fas fa-cloud"></i></div>
                            <div class="media-body">
                              <span class="u-header__promo-title">Consultoria Cloud </span>
                              <small class="u-header__promo-text">Gerenciamento de serviços Cloud</small>
                            </div>
                          </div>
                        </RouterLink>
                      </div>
                      <!--menu title with subtitle and icon item end-->
                    </div>

                  </div>
                </div>
                <!-- End Demos - Mega Menu -->
              </li>
              <!--hosting end-->

              <!--support start-->
              <li class="nav-item custom-nav-item">
                <RouterLink to="/suporte" class="nav-link custom-nav-link" activeClass="active" @click="closeMenu">
                  Suporte</RouterLink>
              </li>
              <!--support end-->

              <!--button start-->
              <li class="nav-item header-nav-last-item d-flex align-items-center">
                <a class="btn primary-solid-btn animated-btn" href="https://wa.me/12076005502?text=w27402675"
                  target="_blank">
                  Contato
                </a>
              </li>
              <!--button end-->
            </ul>
          </div>
          <!--main menu end-->
        </nav>

      </div>
    </div>
    <!--main header menu end-->
  </header>

  <main>
    <!-- <RouterView /> -->
    <router-view v-slot="{ Component }">
      <!-- Use a custom transition or fallback to `fade` -->
      <transition name="scale" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </main>

  <footer class="footer-section">
    <!--footer top start-->
    <div class="footer-top gradient-bg">
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <div class="row footer-top-wrap">
              <div class="col-12">
                <div class="footer-nav-wrap text-white">
                  <ul class="list-inline security-icon-list text-center ">
                    <li class="list-inline-item" style="margin-top: 50px;"><img src="assets/img/logo-white.png"
                        alt="Cloudger Logo" class="img-fluid" /></li>
                    <p>Gerenciamento de ambientes Cloud</p>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-8 text-center">
            <div class="row footer-top-wrap">
              <div class="col-md-6 col-sm-6">
                <div class="footer-nav-wrap text-white">
                  <h4 class="text-white">SERVIÇOS</h4>
                  <ul class="nav flex-column">
                    <li class="nav-item">
                      <RouterLink class="nav-link" to="/hospedagem">Hospedagem Cloud Empresarial</RouterLink>
                    </li>
                    <li class="nav-item">
                      <RouterLink class="nav-link" to="/arquiva.email">Arquiva.email</RouterLink>
                    </li>
                    <!-- <li class="nav-item">
                      <RouterLink class="nav-link" to="/backup">Backup de arquivos</RouterLink>
                    </li> -->
                    <li class="nav-item">
                      <RouterLink class="nav-link" to="/consultoria-cloud">Consultoria Cloud</RouterLink>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- <div class="col-md-3 col-sm-6">
                <div class="footer-nav-wrap text-white">
                  <h4 class="text-white">TERMOS</h4>
                  <ul class="nav flex-column">
                    <li class="nav-item">
                      <a class="nav-link" href="#">Legal Information</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#">Privacy Policy</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#">Report Abuse</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#">Terms of Service</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#">WHOIS Lookup</a>
                    </li>
                  </ul>
                </div>
              </div> -->
              <div class="col-md-6 col-sm-6 text-center">
                <div class="footer-nav-wrap text-white">
                  <h4 class="text-white">SUPORTE</h4>
                  <ul class="nav flex-column">
                    <li class="nav-item">
                      <RouterLink to="/suporte" class="nav-link">Suporte</RouterLink>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="https://wiki.cloudger.host" target="_blank">Wiki</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="https://cloudger.statuspage.io/" target="_blank">Status</a>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--footer top end-->

    <!--footer copyright start-->
    <div class="footer-bottom gray-light-bg py-3">
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-md-5 col-lg-5">
            <p class="copyright-text pb-0 mb-0">Copyrights © {{ new Date().getFullYear() }} - Todos os direitos
              reservados
            </p>
          </div>
          <!-- <div class="col-md-7 col-lg-6">
            <div class="payment-method text-right">
              <ul class="list-inline">
                <li class="list-inline-item">
                  <img src="assets/img/payment-amex.svg" alt="amex" width="50" />
                </li>
                <li class="list-inline-item">
                  <img src="assets/img/payment-visa.svg" alt="visa" width="50" />
                </li>
                <li class="list-inline-item">
                  <img src="assets/img/payment-mastercard.svg" alt="mastercard" width="50" />
                </li>
                <li class="list-inline-item">
                  <img src="assets/img/payment-paypal.svg" alt="paypal" width="50" />
                </li>
                <li class="list-inline-item">
                  <img src="assets/img/payment-bitcoin.svg" alt="bitcoin" width="50" />
                </li>
              </ul>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <!--footer copyright end-->
  </footer>

</template>

<style scoped></style>
